@import 'styles/_variables.scss';

.commentFrame {
	border: 1px solid $primary_black;
	border-radius: 5px;

	min-height: 75%;

	padding: 10px;
}
