@import "styles/_variables.scss";

.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.title {
  position: fixed;

  padding: 15px 0;

  background-color: $primary-white;
}

.table {
  margin-top: 70px;

  border: 2px solid red;
}

.cell {
  padding: 5px 10px;

  border: 2px solid red;

  text-align: center;
  line-height: 1.2;
}
