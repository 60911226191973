@import "styles/_variables.scss";
.modalContainer{
	display: none;
	position: fixed;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	z-index: 9998;
	
	&.active{
		display: flex;
		justify-content: center;
		align-items: center;
	}
	
	.modalBg{
		position: absolute;
		top: 0;
		left: 0;
		width: 100vw;
		height: 100vh;
		z-index: 9998;
		background-color: rgba(60, 60, 60, 0.8);
		transition: all $transition_duration;
		backdrop-filter: blur(4px);
		
		&:hover{
			backdrop-filter: none;
			cursor: pointer;
		}
	}
	
	.modal{
		width: 600px;
		height: 500px;
		background-color: $primary_white;
		border: 1px solid $secondary_white;
		box-shadow: 0 0 8px -2px $secondary_white;
		border-radius: 8px;
		padding: 16px;
		z-index: 9998;
		
		.modalBody{
			height: 420px;
			overflow-y: auto;
			
			textarea{
				height: 70%;
			}
		}
		
		.modalFooter{
			padding-top: 16px;
		}
	}
}