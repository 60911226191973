@import "styles/_variables.scss";

.preloader{
	position: fixed;
	width: 100vw;
	height: 100vh;
	top: 0;
	left: 0;
	z-index: 9999;
	background-color: rgba(247, 252, 252, .65);
	backdrop-filter: blur(6px);
	display: none;
	align-items: center;
	justify-content: center;
	
	&.small {
		width: auto;
		height: auto;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);
		background-color: transparent;
		backdrop-filter: unset;
	}

	&.active{
		display: flex;
	}
	
	img{
		width: 120px;
		
		&.spin{
			width: 350px;
		}
		
		&.logo{
			position: absolute;
			margin-right: 16px;
		}
	}
}