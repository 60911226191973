@import "styles/_variables.scss";

.button{
	display: block;
	padding: 0.5rem 1rem;
	border-radius: 2rem;
	letter-spacing: 1px;
	font-weight: 600;
	font-size: 12px;
	text-align: center;
	text-transform: uppercase;
	transition: .1s background-color;
	cursor: pointer;
	
	&.disabled{
		cursor: not-allowed;
		opacity: .6;
	}
	
	&.default{
		color: $sidebar_color;
		border: 1px solid $sidebar_color;
		
		&:hover{
			color: $primary_white;
			background-color: $sidebar_color_accent;
		}
	}
	
	&.accent{
		border: 1px solid $accent_color;
		background-color: $accent_color;
		color: $primary_white;
		
		&:hover{
			background-color: $accent_color_dark;
		}
	}
	
	&.danger{
		border: 1px solid rgba(220, 53, 69, .3);
		background-color: $red;
		color: $primary_white;
		
		&:hover{
			background-color: $red_dark;
		}
	}
	
	&.white{
		border: 1px solid $gray;
		background-color: $primary_white;
		
		&:hover{
			background-color: $gray_light;
		}
	}
}