@font-face {
	font-family: "Mulish";
	src: url("./fonts/Mulish/Mulish-ExtraLight.ttf") format("truetype");
	font-weight: 200;
	font-style: normal;
}

@font-face {
	font-family: "Mulish";
	src: url("./fonts/Mulish/Mulish-ExtraLightItalic.ttf") format("truetype");
	font-weight: 200;
	font-style: italic;
}

@font-face {
	font-family: "Mulish";
	src: url("./fonts/Mulish/Mulish-Light.ttf") format("truetype");
	font-weight: 300;
	font-style: normal;
}

@font-face {
	font-family: "Mulish";
	src: url("./fonts/Mulish/Mulish-LightItalic.ttf") format("truetype");
	font-weight: 300;
	font-style: italic;
}

@font-face {
	font-family: "Mulish";
	src: url("./fonts/Mulish/Mulish-Regular.ttf") format("truetype");
	font-weight: 400;
	font-style: normal;
}

@font-face {
	font-family: "Mulish";
	src: url("./fonts/Mulish/Mulish-Italic.ttf") format("truetype");
	font-weight: 400;
	font-style: italic;
}

@font-face {
	font-family: "Mulish";
	src: url("./fonts/Mulish/Mulish-Medium.ttf") format("truetype");
	font-weight: 500;
	font-style: normal;
}

@font-face {
	font-family: "Mulish";
	src: url("./fonts/Mulish/Mulish-MediumItalic.ttf") format("truetype");
	font-weight: 500;
	font-style: italic;
}

@font-face {
	font-family: "Mulish";
	src: url("./fonts/Mulish/Mulish-SemiBold.ttf") format("truetype");
	font-weight: 600;
	font-style: normal;
}

@font-face {
	font-family: "Mulish";
	src: url("./fonts/Mulish/Mulish-SemiBoldItalic.ttf") format("truetype");
	font-weight: 600;
	font-style: italic;
}

@font-face {
	font-family: "Mulish";
	src: url("./fonts/Mulish/Mulish-Bold.ttf") format("truetype");
	font-weight: 700;
	font-style: normal;
}

@font-face {
	font-family: "Mulish";
	src: url("./fonts/Mulish/Mulish-BoldItalic.ttf") format("truetype");
	font-weight: 700;
	font-style: italic;
}

@font-face {
	font-family: "Mulish";
	src: url("./fonts/Mulish/Mulish-Black.ttf") format("truetype");
	font-weight: 800;
	font-style: normal;
}

@font-face {
	font-family: "Mulish";
	src: url("./fonts/Mulish/Mulish-BlackItalic.ttf") format("truetype");
	font-weight: 800;
	font-style: italic;
}

@font-face {
	font-family: "Playfair Display";
	src: url("./fonts/Playfair-Display/PlayfairDisplay-Italic.ttf") format("truetype");
	font-weight: 400;
	font-style: italic;
}

@font-face {
	font-family: "Playfair Display";
	src: url("./fonts/Playfair-Display/PlayfairDisplay-Bold.ttf") format("truetype");
	font-weight: 700;
	font-style: italic;
}

@font-face {
	font-family: "Material Icons";
	font-style: normal;
	font-weight: 400;
	src: local("Material Icons"),
	local("MaterialIconsRound-Regular"),
	url("./fonts/MaterialIconsRound-Regular.otf");
}

.material-icons {
	font-family: "Material Icons";
	font-weight: normal;
	font-style: normal;
	font-size: 24px;  /* Preferred icon size */
	display: inline-block;
	line-height: 1;
	text-transform: none;
	letter-spacing: normal;
	word-wrap: normal;
	white-space: nowrap;
	direction: ltr;
	
	/* Support for all WebKit browsers. */
	-webkit-font-smoothing: antialiased;
	/* Support for Safari and Chrome. */
	text-rendering: optimizeLegibility;
	
	/* Support for Firefox. */
	-moz-osx-font-smoothing: grayscale;
	
	/* Support for IE. */
	font-feature-settings: 'liga';
}