.supplyDeniedMark {
  span {
    position: absolute;

    text-transform: uppercase;
    color: coral;

    border: 1px solid coral;
    border-radius: 5px;

    padding: 0 5px;
    margin-top: -15px;
  }
}
