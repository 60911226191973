@import "styles/_variables.scss";

.boardContainer{
	width: 100vw;
	height: calc(100vh - 100px);
	margin-top: 45px;
	overflow-x: auto;
	padding: 0 .3rem;
	
	table{
		.heading{
			border: none !important;
			font-weight: 500;
			text-align: center;
		}
		
		th:not(:first-child).heading{
			border-bottom: .5px solid #dee2e6 !important;
		}
		
		tbody{
			tr{
				td{
					border: 1px solid #dee2e6;
					padding: .5rem .4rem;
					max-width: 43px;
					min-width: 43px;
					white-space: nowrap;
					overflow: hidden;
					text-overflow: ellipsis;
					font-size: 16px;
				}
			}
		}
	}
}

th, td{
	&.b1{
		background-color: $peach;
		border-color: $peach_darker !important;
		
		input{
			background-color: $peach;
		}
	}
	
	&.b2{
		background-color: $yellow;
		border-color: $yellow_darker !important;
		
		input{
			background-color: $yellow;
		}
	}
	
	&.b3{
		background-color: $sky;
		border-color: $sky_darker !important;
		
		input{
			background-color: $sky;
		}
	}
	
	&.b4{
		background-color: $purple;
		border-color: $purple_darker !important;
		
		input{
			background-color: $purple;
		}
	}
	
	&.b5{
		background-color: $salat !important;
		border-color: $salat_darker !important;
		
		input{
			background-color: $salat;
		}
		
		&.bold{
			border: 1px solid #58ba7c !important;
			font-weight: 700;
		}
	}
	
	&.b6{
		background-color: $beige;
		border-color: $beige_darker !important;
		
		input{
			background-color: $beige;
		}
	}
	
	&.b7{
		background-color: $aqua !important;
		border-color: $aqua_darker !important;
		
		input{
			background-color: $aqua;
		}
		
		&.bold{
			border: 1px solid #4db2a4 !important;
			font-weight: 700;
		}
	}
	
	&.b8{
		background-color: $lilac;
		border-color: $lilac_darker !important;
		
		input{
			background-color: $lilac;
		}
	}
}