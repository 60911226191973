@import "styles/_variables.scss";

.filterContainer{
	position: fixed;
	top: 7rem;
	left: initial;
	right: -$filter_width - 20px;
	width: $filter_width;
	padding: .8rem 1.5rem;
	transition: right $filter_transition_duration;
	text-align: center;
	background: $primary_white;
	box-shadow: 0 0 10px -2px $secondary_white;
	border-radius: 8px;
	z-index: 9000;
	
	&.open{
		right: 20px;
	}
	
	.heading{
		display: block;
		font-size: 16px;
		font-weight: 600;
		margin-bottom: .8rem;
	}
	
	.filterOff{
		position: absolute;
		top: .8rem;
		right: 1.5rem;
		color: #6c757d;
		cursor: pointer;
	}
	
	label{
		margin-bottom: 0 !important;
		
		span{
			display: none;
		}
	}
	
	input{
		border: 1px solid $secondary_white !important;
		border-radius: 5px !important;
		padding: .05rem .4rem .05rem .4rem !important;
		margin-left: 0 !important;
		margin-bottom: .5rem !important;
		width: 100% !important;
		font-size: 14px !important;
		letter-spacing: 1px !important;
		
		&.transparent{
			border: none;
			border-bottom: 1px solid $secondary_white;
			border-radius: 0;
		}
	}
	
	.router{
		cursor: pointer;
	}
	
	.accent{
		font-weight: 600;
	}
}