@import "styles/_variables.scss";
.indicator{
	display: inline-block;
	padding: 7px;
	border-radius: 25px;
	
	&.danger{
		background-color: $red;
	}
	
	&.warning{
		background-color: darkorange;
	}
	
	&.success{
		background-color: #7cdf72;
	}
	
	&.blued{
		background-color: cornflowerblue;
	}
}