@import "styles/_variables.scss";

.inputLabel{
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;
	font-size: 18px;
	margin-bottom: 2.5rem;
	
	.required{
		color: $red;
		margin-left: .1rem;
	}
	
	input{
		display: block;
		width: 330px;
		padding: 0.5rem 1rem;
		margin-left: 1.5rem;
		border: 1px solid $secondary_white;
		border-radius: 2rem;
		outline: none;
		background-color: $primary_white;
		letter-spacing: 1px;
		font-weight: 500;
		opacity: .7;
		font-size: 14px;
		transition: .1s border-color;
		
		&:focus{
			border-color: $accent_color;
		}
		
		&.error{
			border-color: $red;
		}
		
		&[disabled]{
			color: $gray_dark;
		}
	}
	
	@media(max-width: $width_mobile){
		flex-direction: column;
		font-size: 16px;
		margin-bottom: 1.5rem;
		
		input{
			margin-left: 0;
			width: 100%;
		}
	}
}