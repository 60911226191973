@import 'styles/_variables.scss';

.tabs {
  display: flex;
  flex-direction: column;

  padding: 10px;
}

.tabs__header {
  display: flex;
  align-items: flex-start;
  gap: 10px;

  margin: 0 10px;

  &:has(.tabs__item--active:nth-child(1)) {
    + .tabs__display {
      border-radius:  0 6px 6px 6px;
    }
  }
}

.tabs__item {
  border-radius: 6px;

  transition: background-color 0.2s ease;

  &:not(.tabs__item--active) {
    box-shadow: 0 0 10px -4px $secondary_white;

    &:hover {
      background-color: $accent_color_ultra_light;

      transition: background-color 0.2s ease;
  
      .tabs__item-caption {
        color: $white;

        transition: color 0.2s ease;
      }
    }
  }

  &.tabs__item--active {
    position: relative;
    top: 1px;

    height: 55px;

    border-radius: 6px 6px 0 0;
    border-bottom: none;

    background-color: $primary_white;

    box-shadow: 0 -6px 10px -4px $secondary_white;

    .tabs__item-caption {
      font-weight: 700;
    }
  }
}

.tabs__button {
  border-style: none;
  border-radius: 6px;

  padding: 10px;

  min-width: 250px;
  max-width: 350px;

  height: 100%;

  background-color: transparent;
}

.tabs__item-caption {
  word-break: break-all;

  text-transform: uppercase;

  color: $accent_color_light;
}

.tabs__display {
  padding: 10px;
  margin: 0 10px;

  border-radius:  6px;

  box-shadow: 0 0 10px -4px $secondary_white;
}
