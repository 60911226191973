@import "styles/_variables.scss";
.main{
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	
	h2{
		color: $secondary_black;
		font-size: 22px;
		font-weight: 700;
		letter-spacing: .2px;
		margin: 2rem 0;
	}
	
	form{
		margin-bottom: 2.5rem;
		
		input{
			width: 400px;
		}
	}
	
	@media(max-width: $width_tablet){
		height: auto;
		
		h2{
			font-size: 18px;
			margin: 1.5rem 0;
		}
		
		form{
			width: 100%;
			
			span{
				margin-bottom: .5rem;
			}
			
			input{
				width: 100%;
			}
		}
	}
}