@import "styles/_variables.scss";

.wrapper {
  &.opened {
    .list {
      display: flex;
      flex-direction: column;
      gap: 8px;

      position: absolute;

      background-color: $primary_white;

      font-size: 18px;
      font-weight: 500;

      z-index: 9999;

      margin-top: 10px;
      padding: 5px 10px;

      max-height: 300px;
      max-width: fit-content;
      overflow: scroll;

      border-radius: 4px;

      box-shadow: 0px 0px 5px 0px $accent_color_light;
    }
  }
}

.header {
  position: relative;

  .clearButton {
    position: absolute;
    top: -6px;
    right: -6px;
    
    height: 15px;
    width: 15px;

    border-radius: 50%;

    background-color: coral;

    color: $white;
    text-align: center;

    line-height: 1;

    z-index: 2;

    cursor: pointer;

    &:hover {
      background-color: rgb(206, 103, 65);
    }
  }
}

.input {
  // background-color: $white;
  background-color: transparent;
}

.button {
  min-width: 80px;
  max-width: fit-content;
  height: 30px;

  border-style: none;
  border: 1px dotted $secondary_white;
  border-radius: 4px;

  background-color: transparent;

  transition: border-color 0.2s ease;

  .placeholder {
    color: $secondary_white;
    font-style: italic;
  }

  &:hover {
    border-color: black;
    transition: border-color 0.2s ease;
  }
}

.list {
  display: none;
}

.option {
  cursor: pointer;

  color: $accent_color_light;
  transition: opacity 0.2s ease;

  &:hover {
    opacity: 0.5;
    transition: opacity 0.2s ease;
  }
}

.empty {
  color: $accent_color_light;
  font-size: 14px;
}
