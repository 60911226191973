@import 'styles/_variables.scss';

.shiftingTargetAddress {
	text-align: center;

	.subLine {
		display: inline-flex;
	}
}

.subLine {
	display: flex;
	justify-content: flex-end;
	align-items: center;

	padding: 5px;

	&.stretch {
		justify-content: space-between;
	}

	.buttonsWrapper {
		display: flex;
		align-items: center;
	}

	span {
		border-radius: 3px;

		cursor: pointer;

		transition: all $transition_duration;

		&:hover{
			color: $primary_white;
			background-color: $sidebar_color_accent;

			transition: all $transition_duration;
		}
	}
}
