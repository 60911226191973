.datesClearButton {
  margin-left: 20px;

  border: 1px solid black;

  background: transparent;

  width: 25px;

  &:hover {
    background-color: aliceblue;
  }
}
