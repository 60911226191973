@import "styles/_variables.scss";

.pagination-font-styles {
  font-weight: 400;
  font-size: 12px;
  line-height: 1.25;
  display: flex;
  color: $gray_dark;
}

.page {
  width: 40px;
  height: 40px;

  border-radius: 50%;

  &:not(.page_current) {
    *:hover {
      cursor: pointer;
    }

    border: 1px solid transparent;
    transition: all 0.2s ease;

    &:hover {
      border: 1px solid $gray_dark;
      transition: all 0.2s ease;
    }
  }
}

.page-button {
  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;
  height: 100%;

  border-radius: 50%;
  border-style: none;

  background-color: transparent;

  @extend .pagination-font-styles;

  &:focus-visible {
    outline: 3px solid $lilac_darker;
  }
}

.page_current {
  .page-button {
    color: $white;
    font-weight: 700;
    font-size: 18px;

    background: $salat_darker;
  }
}
