$filter_width: 300px;
$filter_transition_duration: .3s;

$transition_duration: .2s;

$navbar_height: 56px;
$sidebar_width: 256px;
$sidebar_width_minified: 56px;

$border_radius: 8px;

$primary_black: #000;
$secondary_black: #3c3c3c;

$white: #FFFFFF;
$primary_white: #F7FCFC;
$secondary_white: #BACFCF;

$sidebar_color: #343637;
$sidebar_color_accent: #2A2D2F;

$accent_color: #3B4E39;
$accent_color_light: #526D4F;
$accent_color_ultra_light: #7ca477;
$accent_color_dark: #314130;

$red: #974646;
$red_dark: #803939;

$gray_dark: #b0b1b3;
$gray: #d8d9dc;
$gray_light: #dbdbde;

$peach: #fed6bc;
$peach_darker: #f6c8aa;

$yellow: #fffadd;
$yellow_darker: #faf1bc;

$sky: #def7fe;
$sky_darker: #c6eefa;

$purple: #e7ecff;
$purple_darker: #d4dcfa;

$salat: #c3fbd8;
$salat_darker: #94faba;

$beige: #fdeed9;
$beige_darker: #fbe3bf;

$lime: #f6fff8;
$lime_darker: #d9fbe2;

$aqua: #b5f2ea;
$aqua_darker: #7fedde;

$lilac: #c6d8ff;
$lilac_darker: #b2c9fb;

$width_mobile: 425px;
$width_tablet: 768px;
$width_desktop_small: 992px;
$width_desktop: 1200px;