@import 'styles/_variables.scss';

.wrapper {
  display: flex;
  justify-content: space-between;

  padding: 8px 16px;

  &.without-new-button {
    justify-content: flex-end;
  }
}

.inner-wrapper {
  display: flex;
  gap: 8px;
}

.button {
  border-style: none;
  border-radius: 20px;

  min-height: 40px;

  padding: 0;

  span {
    padding: 0 18px;

    font-size: 14px;
    font-weight: 600;
    text-transform: uppercase;
  }

  &.new {
    background-color: $accent_color;

    color: $white;

    transition: background-color 0.2s ease;

    &:hover {
      background-color: $accent_color_light;
      transition: background-color 0.2s ease;
    }
  }

  &.regular {
    border: 1px solid transparent;
    background-color: $accent_color_ultra_light;

    color: $white;

    transition: background-color 0.2s ease, color 0.2s ease;

    &:hover {
      border: 1px solid $gray_dark;
      background-color: transparent;

      color: $gray_dark;

      transition: background-color 0.2s ease, color 0.2s ease;
    }
  }
}
