@import "styles/_variables.scss";

.navbarTop{
	position: fixed;
	top: 0;
	left: 0;
	width: 100vw;
	height: $navbar_height;
	background-color: $accent_color;
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding-left: 16px;
	color: $primary_white;
	z-index: 8999;
	
	.leftSide{
		display: flex;
		
		.brand{
			display: flex;
			align-items: center;
			cursor: pointer;
			
			img{
				height: 40px;
				margin-right: 16px;
			}
			
			span{
				font-size: 20px;
				letter-spacing: 4px;
			}
		}
		
		.crumbs{
			display: flex;
			align-items: center;
			font-size: 16px;
		}
	}
	
	.menu{
		display: flex;
		
		li{
			position: relative;
			display: flex;
			align-items: center;
			padding: 0 16px;
			height: $navbar_height;
			font-size: 16px;
			cursor: pointer;
			transition: background-color $transition_duration;
			
			&:hover{
				background-color: $accent_color_dark;
				
				& > .dropdown{
					display: block;
				}
			}
			
			&.cabinet{
				span:not(:last-of-type){
					margin-right: 8px;
				}
			}
			
			.dropdown{
				display: none;
				position: absolute;
				top: $navbar_height;
				left: 0;
				width: 100%;
				z-index: 9999;
				
				.dropdownItems{
					margin-top: 8px;
					background-color: $sidebar_color;
					border: 1px solid $sidebar_color_accent;
					border-radius: $border_radius;
					
					li{
						display: block;
						height: auto;
						font-size: 14px;
						padding: 0;
						text-align: center;
						
						&:hover{
							background-color: $sidebar_color_accent;
						}
						
						&:first-of-type{
							border-radius: $border_radius $border_radius 0 0;
						}
						
						&:last-of-type{
							border-radius: 0 0 $border_radius $border_radius;
						}
						
						a{
							display: block;
							padding: 8px;
						}
					}
				}
			}
		}
	}
}