@import 'styles/_variables.scss';

.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  height: 100%;

  overflow-y: auto;
}

.title {
  position: fixed;

  padding: 15px 0;

  background-color: $primary-white;

  font-size: 18px;
  font-weight: 500;
}

.table {
  margin-top: 70px;
  margin-bottom: 10px;

  border: 2px solid red;

  &:has(.deniedSupplyRow) {
    width: 100%;
  }
}

.deniedSupplyRow {
  height: 40px;
}

.cell {
  padding: 5px 10px;

  border: 2px solid red;

  text-align: center;
  line-height: 1.2;

  &.error {
    color: red;
    font-weight: bold;
  }
}

.toSuppliesArchiveButtonWrapper {
  width: 100%;

  .button {
    border-style: none;
    border-radius: 20px;
  
    min-height: 40px;
    width: 100%;
  
    padding: 0;
  
    span {
      padding: 0 18px;
  
      font-size: 14px;
      font-weight: 600;
      text-transform: uppercase;
    }
  
    &.regular {
      border: 1px solid transparent;
      background-color: $accent_color_ultra_light;
  
      color: $white;
  
      transition: background-color 0.2s ease, color 0.2s ease;
  
      &:hover {
        border: 1px solid $gray_dark;
        background-color: transparent;
  
        color: $gray_dark;
  
        transition: background-color 0.2s ease, color 0.2s ease;
      }
    }
  }
}
