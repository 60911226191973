@import 'styles/_variables.scss';

.floatPanel{
	position: fixed;
	right: 8px;
	padding: 4px;
	text-align: center;
	background: $primary_white;
	border-radius: 8px;
	z-index: 9998;
	
	&.controlsPanel{
		top: 8px;
	}
	
	&.metersPanel{
		display: none;
		position: absolute;
		top: 2.5rem;
		left: -.3rem;
		min-width: 120%;
		
		&.visible{
			display: block;
		}
		
		span{
			float: none;
			margin: 0 !important;
			
			&.heading{
				font-weight: 700;
			}
		}
	}
	
	div{
		font-size: 12px;
	}
	
	span{
		float: right;
		
		&:not(:last-child){
			margin-left: 4px;
		}
	}
}
