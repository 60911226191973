@import "styles/_variables.scss";

.alertContainer{
	display: flex;
	flex-direction: column;
	align-items: center;
	position: fixed;
	top: 70px;
	right: -500px;
	padding: 1rem 2rem;
	border-radius: 25px;
	font-size: 18px;
	color: #fff;
	box-shadow: 0 1px 3px 0 #6c757d;
	z-index: 9999;
	-webkit-transition: right .5s linear;
	transition: right .5s linear;
	white-space: pre-line;
	
	&.active{
		right: 30px;
	}
	
	&.danger{
		background: linear-gradient(93.72deg, #d74e45 6.58%, #ee4136 98.31%);
	}
	
	&.success{
		background: linear-gradient(93.72deg, #40b576 6.58%, #31ce41 98.31%);
	}
	
	&.default{
		background: #fff;
		color: #333;
		
		.btns span{
			border-bottom: 1px solid #333;
		}
	}
	
	.btns{
		span{
			border-bottom: 1px solid #fff;
			padding: 0 .2rem;
			cursor: pointer;
			
			&:not(:first-child){
				margin-left: 15px;
			}
		}
	}
}
