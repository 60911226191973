@import "styles/_variables.scss";

.main{
	height: calc(100vh - 56px);
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	
	.form{
		padding: 0;
		margin: 0;
		
		&.formHidden{
			display: none;
		}
		
		&.withoutLabel{
			label span{
				display: none;
			}
			
			input{
				margin: 0;
			}
		}
		
		div{
			margin-bottom: 2.5rem;
		}
	}
	
	@media(max-width: $width_mobile){
		height: auto;
		
		.badges{
			top: 1.5rem;
		}
		
		form{
			width: 100%;
			
			span{
				margin-bottom: .5rem;
			}
		}
	}
}