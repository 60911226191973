@import 'styles/_variables.scss';

.usersTable{
	text-align: center;
	border-spacing: 0 1rem;
	border-collapse: separate;
	width: 100%;
	padding: 16px;
	
	&.collapseTable{
		display: none;
		text-align: left;
		margin-top: .7rem;
		margin-bottom: 0;
		border-spacing: 0 .3rem;
		
		&.collapseVisible{
			display: block; /*display: table; приведет к большим отступам*/
		}
		
		tr{
			background: transparent;
			border-radius: 0;
			box-shadow: none;
			
			td{
				border-radius: 0;
			}
			
			&:hover{
				background: transparent;
			}
		}
	}
	
	th, td{
		border: none;
	}
	
	th{
		position: relative;
		font-size: 14px;
		color: $gray_dark;
		font-weight: 600;
		padding-bottom: 0;
		
		.icon{
			position: relative;
			padding: .3rem;
			color: $accent_color;
			cursor: pointer;
			background: $primary_white;
			border-radius: 25px;
			box-shadow: 0 0 10px -7px $gray_light;
			
			&:hover{
				background: rgba(124, 113, 197, .05);
			}
		}
	}
	
	tbody{
		tr{
			background: $primary_white;
			border-radius: 25px;
			box-shadow: 0 0 10px -4px $secondary_white;
			
			&:hover{
				background: rgba(186, 207, 207, .1);
			}
			
			td{
				padding: .8rem .3rem .5rem .3rem;
				
				&.editing{
					padding-top: .5rem;
				}
				
				label{
					margin: 0;
					justify-content: center;
					
					span{
						display: none;
					}
					
					input{
						width: 100%;
						margin-left: 0;
					}
				}
				
				.icon{
					padding: 0;
					color: $accent_color;
					cursor: pointer;
					
					&.remove{
						color: $red;
					}
				}
				
				&:first-child{
					border-radius: 25px 0 0 25px;
				}
				
				&:last-child{
					border-radius: 0 25px 25px 0;
				}
			}
		}
	}
	
	@media(max-width: $width_tablet){
		th, td{
			font-size: 12px;
		}
		
		th{
			.icon{
				font-size: 20px;
				padding: .2rem;
			}
		}
		
		td{
			span:not(.icon){
				display: block;
				min-width: 135px;
			}
			
			.icon{
				font-size: 20px;
			}
		}
	}
}
