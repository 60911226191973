@import "styles/_variables.scss";

.sidebar{
	position: fixed;
	top: $navbar_height;
	left: 0;
	height: calc(100vh - 56px);
	width: $sidebar_width;
	background-color: $sidebar_color;
	padding: 16px 0;
	z-index: 9998;
	transition: width $transition_duration;
	
	.menu{
		height: 100%;
		
		li{
			width: 100%;
			color: $primary_white;
			cursor: pointer;
			transition: background-color $transition_duration;
			
			&:not(:last-of-type){
				margin-bottom: 16px;
			}
			
			&:hover, &.active{
				background-color: $sidebar_color_accent;
				
				span{
					color: $accent_color_light;
				}
			}
			
			&.minify{
				padding: 8px 16px;
				text-align: center;
			}
			
			&.user{
				position: absolute;
				bottom: 16px;
				display: flex;
				align-items: center;
				justify-content: space-between;
			}
			
			a {
				display: flex;
				align-items: center;
				width: 100%;
				padding: 8px 16px;
				
				span{
					margin-right: 8px;
					transition: color $transition_duration;
				}
				
				&:hover > .dropdown {
					display: block;
				}
			}
			
			.dropdown{
				display: none;
				position: absolute;
				left: $sidebar_width;
				top: 0;
				width: 208px;
				height: calc(100vh - 56px);
				padding: 16px 0;
				
				.dropdownItems{
					background-color: $primary_white;
					border-radius: 8px;
					border: 1px solid $secondary_white;
					height: 100%;
					overflow-y: auto;
					
					li{
						color: $primary_black;
						border-bottom: 1px solid $secondary_white;
						margin: 0;
						
						&:hover{
							background-color: $secondary_white;
						}
					}
				}
			}
		}
	}
	
	&.minified{
		width: $sidebar_width_minified;
		
		.linkName, .dropdownIcon{
			display: none;
		}
		
		.dropdown{
			left: $sidebar_width_minified !important;
		}
	}
}
