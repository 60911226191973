@import 'styles/_variables.scss';

.button {
  border-style: none;

  background-color: transparent;
}

.icon {
  padding: 0;

  color: $accent_color;

  cursor: pointer;

  &.close {
    color: $red;
  }
}
