@import 'styles/_variables.scss';

.mainTable {
	width: 100%;
	margin-top: 130px;
	border-collapse: separate;
	border-spacing: 0;
	z-index: 9;
	
	th, td {
		border: .5px solid $secondary_white !important;
		vertical-align: middle;
		position: relative;
		
		&.borderNone {
			border: none !important;
		}
		
		&.dateInput {
			display: flex;
			justify-content: space-around;
			align-items: center;

			label{
				display: block;
				
				input{
					max-width: 150px;
				}
			}
		}
		
		&.storageFixed {
			input{
				width: 90px;
			}
		}
	}
	
	&.fixed {
		position: fixed;
		left: $sidebar_width_minified + 16px;
		width: calc(100% - 56px);
		background: $primary_white;
		z-index: 9000;
		margin: 0;
		
		th {
			border: none !important;
			border-bottom: .5px solid #dee2e6 !important;
		}
	}
	
	&.top0 {
		margin-top: 0;
	}
	
	&.overflow {
		// width: 115vw;
		// width: 100vw;
		
		.name {
			width: 23%;
		}
		
		.cifer {
			width: 2%;
		}
		
		.meters {
			width: 3%;
		}
		
		.date {
			width: 6%;
			overflow-wrap: break-word;
		}
	}
	
	&.overflowWide {
		width: 180vw;
		
		.name {
			width: 20%;
		}
		
		.cifer {
			width: 4%;
		}
		
		.meters {
			width: 5%;
		}
		
		.date {
			width: 5%;
			overflow-wrap: break-word;
		}
	}
	
	label, div {
		margin: 0;
		
		input{
			margin: 0;
			padding: 0;
			border: none;
			border-radius: 0;
			opacity: 1;
			outline: none;
		}
	}
	
	thead {
		position: sticky;
		background: $primary_white;
		top: 130px;
		z-index: 9000;
		
		&.top0 {
			top: 0;
		}
		
		label {
			display: inline-block;
			width: auto;
		}
		
		div, input {
			display: inline-block;
			max-width: 100px;
		}
		
		th.long {
			label input{
				max-width: 500px;
			}
		}
	}
	
	tbody {
		input {
			width: 100%;
			transition: background-color .3s;
		}
		
		tr {
			transition: background-color .4s, border-color .4s;
			background-color: $primary_white;
			
			td {
				transition: border-color .4s;
			}
			
			&.default {
				background-color: $primary_white;
			}
			
			&.error {
				background-color: rgba(220, 53, 69, .6);
				
				td {
					border-color: rgba(220, 53, 69, .2) !important;
				}
				
				input {
					background-color: inherit;
				}
			}
		}
		
		td {
			padding-left: .3rem;
			padding-right: .3rem;

			&.itemName,
			&.itemGroup {
				white-space: nowrap;
				padding: 0 4px
			}

			&.itemName,
			&.itemArticle {
				position: relative;

				&.inventory-table-row {
					outline: 5px solid red;
					outline-offset: 2px;

					background-color: $peach_darker;

					z-index: 1;
				}
			}
			
			&.center {
				text-align: center;
			}
			
			&.bottom {
				vertical-align: bottom;
			}
			
			&.right {
				min-width: 5%;
				max-width: 8%;
				// max-width: fit-content;
				text-align: right;
			}
			
			&.address {
				min-width: 5%;
				max-width: 8%;
			}
			
			&.name {
				width: 30%;
			}
			
			&.cifer {
				width: 3%;
			}
			
			&.meters {
				width: 8%;
			}
			
			&.date {
				width: 10%;
			}
			
			&.placeholder {
				width: 100px;
				border: none !important;
			}
			
			&.accent {
				border-top: 1px solid #626262 !important;
				border-bottom: 1px solid #626262 !important;
			}
			
			&.accentLeft {
				border-left: 1px solid #626262 !important;
			}
			
			&.accentRight {
				border-right: 1px solid #626262 !important;
			}
			
			&.bold {
				font-weight: 600;
			}
			
			&.router {
				cursor: pointer;
			}
			
			.partLine:not(:last-child) {
				display: block;
				border-bottom: 1px solid $secondary_white;
				padding-bottom: 4px;
				margin-bottom: 4px;
			}
		}
	}
}

.title {
  padding: 15px 0;

  background-color: $primary-white;
}

.table {
  margin-top: 20px;
}

.cell {
  padding: 5px 10px;

  border: 2px solid red;

  text-align: center;
}

.commentFrame {
	border: 1px solid $primary_black;
	border-radius: 5px;

	min-height: 75%;

	padding: 10px;
}
