@import "styles/_variables.scss";

.mapPanelContainer{
	position: absolute;
	top: $navbar_height;
	width: 200px;
	height: calc(100vh - 56px);
	z-index: 8997;
	padding: 16px;
	
	.mapPanel{
		background-color: $primary_white;
		border-radius: 8px;
		height: 100%;
		padding: 8px;
		border: 1px solid $secondary_white;
		overflow-y: auto;
		
		.mapName{
			text-align: center;
			border-bottom: 1px solid $secondary_white;
			letter-spacing: 1px;
			margin-bottom: 4px;
			
			.setName{
				letter-spacing: 1px;
				font-size: 16px;
			}
		}
		
		.mapStorages{
			border-bottom: 1px solid $secondary_white;
			font-size: 14px;
			padding: 8px 0;
			
			div{
				display: flex;
				align-items: center;
				padding: 2px 0;
				
				span{
					cursor: pointer;
					
					&.remove{
						font-size: 14px;
						margin-left: 4px;
						color: $red;
					}
				}
			}
		}
		
		.field{
			padding: 8px 0;
			border-bottom: 1px solid $secondary_white;
			
			.preview{
				width: 56px;
				
				img{
					width: 100%;
					height: 100%;
				}
			}
			
			.fieldInfo{
				display: flex;
				
				.info{
					display: flex;
					flex-direction: column;
					align-items: center;
					width: 100%;
					
					.fieldName{
						display: flex;
						align-items: center;
						font-size: 16px;
						
						span{
							margin-left: 8px;
							font-size: 18px;
							cursor: pointer;
						}
						
						input{
							border: 1px solid $secondary_white;
						}
					}
					
					span{
						display: block;
						font-size: 14px;
					}
				}
			}
			
			.controls{
				display: flex;
				justify-content: space-between;
				margin-top: 8px;
				
				span{
					font-size: 14px;
					padding: 6px;
				}
			}
		}
		
		input{
			background: transparent;
			border: none;
			outline: none;
		}
		
		.dangerZone{
			margin-top: 16px;
			
			span{
				font-size: 10px;
			}
		}
	}
}