@import 'styles/_variables.scss';

.row {
  position: relative;
}

.popup {
  position: absolute;
  left: 30%;
  top: 90%;

  display: flex;
  justify-content: space-between;
  gap: 10px;

  padding: 10px 15px;

  min-width: 300px;
  max-width: 400px;

  z-index: 2;

  border: 2px solid red;
  border-radius: 10px;

  background-color: $peach !important;

  &.top-right {
    left: unset;
    right: 30%;
  }
}

.anchor {
  width: 12px;
  height: 12px;

  position: absolute;
  top: -7px;
  left: 20px;

  transform: rotate(45deg);

  background-color: $peach !important;
  border-left: 1px solid red;
  border-top: 1px solid red;

  &.top-right {
    left: 250px;
  }
}

.message {
  align-self: center;

  user-select: none;

  font-weight: normal;

  span {
    white-space: break-spaces;
  }

  button {
    padding: 0 25px;

    border-style: none;
    border: 1px solid $gray_dark;

    background-color: transparent;

    transition: background-color 0.2s ease;

    &:hover {
      background-color: $peach_darker;
      transition: background-color 0.2s ease;
    }
  }
}

.text {}

.controls {}

.close-button {
  padding: 0;

  width: fit-content;
  height: fit-content;
  aspect-ratio: 1/1;

  border-style: none;
  border: 1px solid $gray_dark;

  background-color: transparent;

  transition: background-color 0.2s ease;

  &:hover {
    background-color: $peach_darker;
    transition: background-color 0.2s ease;
  }
}
