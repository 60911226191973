@import "styles/_variables.scss";
@import "_fonts.scss";

html, body{
	padding: 0;
	margin: 0;
	font-family: "Mulish", "Arial", "sans-serif", sans-serif;
	font-weight: 400;
	letter-spacing: .01rem;
	overflow-x: hidden;
	min-height: 100vh;
	background-color: $primary_white;
}

h1{
	text-align: center;
	margin-top: 16px;
}

h2{
	margin: 16px 0 0 16px;
	
	&.faq{
		text-align: center;
		margin: 32px 0;
	}
}

p{
	margin: 0;
	padding: 4px 16px;
	max-width: 800px;
	
	a{
		color: $accent_color_light;
	}
	
	img{
		max-width: 800px;
		
		&.small{
			max-width: 100px;
		}
		
		&.smaller{
			max-width: 250px;
		}
		
		&.normall{
			max-width: 350px;
		}
		
		&.normaller{
			max-width: 700px;
		}
	}
}

a{
	color: inherit;
	text-decoration: none;
	
	&:hover{
		color: inherit;
	}
}

main {
	position: relative;

	padding-top: $navbar_height;
	padding-left: $sidebar_width;

	transition: padding $transition_duration;
	
	&.wide, &.minified{
		padding-left: $sidebar_width_minified;
	}
}

.stickyContainer{
	// position: fixed;
	height: calc(100vh - $navbar_height);
	overflow: auto;
	transition: width $transition_duration;
}

.leaflet-container{
	height: calc(100vh - 56px) !important;
	width: 100% !important;
	
	.leaflet-control-attribution{
		display: none;
	}
	
	.leaflet-tooltip{
		background: transparent;
		border: none;
		color: #fff;
		box-shadow: none;
		font-size: 16px;
	}
	
	path{
		stroke: #000;
		stroke-width: 3;
		fill: transparent;
		fill-opacity: 1;
		transition: fill .2s;
		
		&:hover{
			fill: #4d4d4d;
			cursor: pointer;
		}
	}
}

*{
	box-sizing: border-box;
}

[contentEditable=true]{
	border: 1px solid $secondary_white;
	border-radius: 5px;
	padding: .05rem .4rem .05rem .4rem;
	background-color: $primary_white;
	
	&.transparent{
		border: none;
		border-bottom: 1px solid $secondary_white;
		border-radius: 0;
	}
}

input{
	@extend [contentEditable=true];
	width: 100%;
	font-size: 14px;
	outline: none;
}

textarea{
	@extend input;
	height: 100%;
}

.select-search{
	position: relative;
	
	&.has-focus{
		.select-search__select{
			display: block;
		}
	}
	
	&.fixed-dropdown{
		.select-search__select{
			max-width: 100%;
		}
	}
	
	.select-search__select{
		display: none;
		position: absolute;
		top: 1.8rem;
		left: 0;
		z-index: 10;
		min-width: 100%;
		max-width: 300%;
		
		ul{
			list-style: none;
			padding: 0;
			margin: 3px 0 0 0;
			background: #fff;
			box-shadow: 0 2px 5px 0 #6c757d;
			border-radius: 5px;
			max-height: 300px;
			overflow-y: auto;
			
			li{
				padding: .3rem .1rem;
				
				&:hover{
					background: #efefef;
				}
				
				button{
					white-space: nowrap;
				}
			}
			
			button{
				@extend input;
				width: 100%;
				height: 100%;
				background: none;
				border: none;
			}
		}
		
		.notFound{
			padding: .2rem;
			text-align: center;
			font-size: .9rem;
		}
	}
}

form{
	.select-search{
		.select-search__input{
			display: block;
			width: 330px;
			padding: 0.5rem 1rem;
			margin-left: 1.5rem;
			border: 1px solid $gray;
			border-radius: 2rem;
			outline: none;
			background-color: $primary_white;
			letter-spacing: 1px;
			font-weight: 500;
			opacity: .7;
			font-size: 14px;
			transition: .1s border-color;
			
			&:focus{
				border-color: $accent_color;
			}
			
			&.error{
				border-color: $red;
			}
			
			&[disabled]{
				color: $gray_dark;
			}
		}
		
		.select-search__select{
			width: 100%;
			top: 2.5rem;
		}
	}
}

.table{
	margin-bottom: 0;
	position: relative;
	left: 0;
	transition: left $filter_transition_duration;
	
	thead{
		&.theadBordered th{
			border: 1px solid #dee2e6;
			border-top: none;
			font-size: 14px;
			vertical-align: middle;
		}
		
		th{
			vertical-align: top;
			// padding-top: .7rem;
			padding: 0;
			text-align: center;
			
			&.sort{
				cursor: pointer;
				
				span{
					border-bottom: 1px dashed #6c757d;
				}
			}
			
			&.sorted:after{
				content: '▼';
				font-size: .6rem;
				margin-left: .2rem;
				color: #6c757d;
			}

			&.rotated:after {
				content: '▲';
				font-size: .6rem;
				margin-left: .2rem;
				color: #6c757d;
			}
			
			&.search{
				width: 300px;
			}
		}
	}
	
	tbody{
		border-top: none !important;

		.select-search-container {
			width: 100px;
		}
		
		tr.adding{
			&:hover{
				--bs-table-hover-bg: transparent;
			}
		}
		
		td{
			border: 1px solid #dee2e6;
			font-size: 14px;

			padding: 0;
			
			&.iconed{
				border: none !important;
				text-align: center;
				width: 30px;
				padding: 0.3rem;
				vertical-align: top;
				
				span{
					display: inline-block;
				}
			}
			
			span{
				display: block;
				
				&.spinner{
					display: inline-block;
				}
			}
		}
	}
}

.roundIcon{
	cursor: pointer;
	color: $sidebar_color;
	border: 1px solid $sidebar_color;
	border-radius: 25px;
	padding: .3rem;
	font-size: 20px;
	transition: all $transition_duration;
	
	&:hover{
		color: $primary_white;
		background-color: $sidebar_color_accent;
	}
	
	&.dangerIcon{
		color: $red;
		border: 1px solid $red;
		
		&:hover{
			color: $primary_white;
			background-color: $red_dark;
		}
	}
	
	&.successIcon{
		color: $accent_color;
		border: 1px solid $accent_color;
		
		&:hover{
			color: $primary_white;
			background-color: $accent_color_dark;
		}
	}
}

ul{
	list-style: none;
	padding: 0;
	margin: 0;
	
	&.list li p:before{
		content: "— ";
	}
}

.spinner{
	animation: spinner 1s linear infinite;
}

.fileIconGroup{
	position: relative;
	display: inline-flex;
	flex-direction: column;
	align-items: center;
	width: 100px;
	margin: 8px 8px 0 0;
	
	.remove{
		position: absolute;
		top: 0;
		right: 4px;
		font-size: 18px;
		color: $red;
		cursor: pointer;
	}
	
	.file{
		font-size: 40px;
		cursor: pointer;
		color: $secondary_black;
	}
	
	.name{
		font-size: 8px;
		text-align: center;
		cursor: pointer;
	}
}

@keyframes spinner{
	from{
		transform: rotate(0deg);
	}
	
	to{
		transform: rotate(360deg);
	}
}
