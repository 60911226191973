@import "styles/_variables.scss";

.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  min-height: 100%;
}

.select {
  background-color: $white;

  border: 1px solid $primary_black;
  border-radius: 3px;

  transition: background-color 0.2s ease;

  &:hover {
    background-color: $sky_darker;
    transition: background-color 0.2s ease;
  }
}
