@import "styles/_variables.scss";

.card{
	padding: 0;
	margin: 0 0 32px 0;
	display: flex;
	flex-direction: column;
	align-items: center;
	
	.img{
		width: 270px;
		height: 220px;
		background-color: $gray_dark;
		background-size: 100% 100%;
		background-position: center;
		border: 1px solid $accent_color;
		border-radius: 15px;
		transition: background .2s;
		
		&:hover{
			background-size: 110% 110%;
		}
		
		&:hover > .imgCovered{
			opacity: 1;
		}
		
		.imgCovered{
			display: flex;
			opacity: 0;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			width: 100%;
			height: 100%;
			background-color: rgba(100, 130, 32, .6);
			border-radius: 15px;
			padding: 1rem 1.7rem;
			transition: opacity .2s;
			
			.buttonText{
				width: 100%;
				text-align: center;
				color: #fff;
				text-transform: uppercase;
				letter-spacing: 2px;
				border: 2px solid #fff;
				border-radius: 20px;
				padding: .6rem 2rem;
				font-size: 14px;
				cursor: pointer;
				transition: background .3s, color .3s;
				
				&:hover{
					background: #fff;
					color: #333;
				}
				
				&:not(:first-child){
					margin-top: 1rem;
				}
				
				span{
					margin-top: 3px;
					font-size: 50px;
				}
			}
		}
	}
	
	.name{
		font-size: 20px;
		margin-top: .3rem;
		text-align: center;
	}
}

.cardDouble{
	display: flex;
	
	.card{
		&:first-child{
			.img{
				border-right: none;
				border-radius: 15px 0 0 15px;
				
				.imgCovered{
					border-radius: 15px 0 0 15px;
				}
			}
		}
		
		&:last-child{
			.img{
				border-left: none;
				border-radius: 0 15px 15px 0;
				
				.imgCovered{
					border-radius: 0 15px 15px 0;
				}
			}
		}
		
		.name{
			font-size: 18px;
		}
	}
}