@import "styles/_variables.scss";

.wrapper {
  width: 40px;
  height: 40px;

  background: $aqua_darker;

  border-radius: 50%;

  transition: opacity 0.2s ease;

  &:hover {
    opacity: 0.5;
    transition: opacity 0.2s ease;
  }
}

.button {
  cursor: pointer;

  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;
  height: 100%;

  border-radius: 50%;
  border-style: none;

  background-color: transparent;

  &:focus-visible {
    outline: 3px solid $lilac;
  }
}

.arrow {
  width: 18px;
  height: 18px;

  color: $white;

  &_rotated {
    transform: rotate(180deg);
  }
}
